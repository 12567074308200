import { tap } from 'rxjs/operators';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {

  constructor(private toastrService: ToastrService, private router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      tap((ev: HttpEvent<any>) => {
        },
        (response: any) => {
          if (response instanceof HttpErrorResponse) {
            if (response.status !== 404) {
              this.toastrService.error(response.error && response.error.message
                ? response.error.message
                : 'Nåt tekniskt. Försök igen (hör av dig till info@vodeville.se om det fortfarande inte funkar så ordnar vi det');
            }
          }
          return observableThrowError(response);
        }));
  }
}
