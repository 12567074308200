import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, HostBinding, Inject, PLATFORM_ID } from '@angular/core';
import { animationFrameScheduler, fromEvent } from 'rxjs';
import { distinctUntilChanged, filter, map, pairwise, share, throttleTime } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

enum VisibilityState {
  Visible = 'visible',
  Hidden = 'hidden'
}

enum Direction {
  Up = 'Up',
  Down = 'Down',
  Bounce = 'Bounce'
}

@Component({
  selector: 'app-sticky-header',
  template: `
    <ng-content></ng-content>`,
  styles: [
    `
      :host {
        position: fixed;
        top: 0;
        width: 100%;
      }
    `
  ],
  animations: [
    trigger('toggle', [
      state(VisibilityState.Hidden, style({ opacity: 0, transform: 'translateY(-100%)' })),
      state(VisibilityState.Visible, style({ opacity: 1, transform: 'translateY(0)' })),
      transition('* => *', animate('200ms ease-in'))
    ])
  ],
  standalone: true
})
export class StickyHeaderComponent implements AfterViewInit {
  private isVisible = true;

  constructor(@Inject(PLATFORM_ID) private _platformId: Object) {
  }

  @HostBinding('@toggle')
  get toggle(): VisibilityState {
    return this.isVisible ? VisibilityState.Visible : VisibilityState.Hidden;
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this._platformId)) {
      const scroll$ = fromEvent(window, 'scroll').pipe(
        throttleTime(0, animationFrameScheduler),
        map(() => window.pageYOffset),
        pairwise(),
        map(([y1, y2]): Direction => (y2 <= 0 ? Direction.Bounce : y2 < y1 ? Direction.Up : Direction.Down)),
        distinctUntilChanged(),
        share()
      );

      const goingUp$ = scroll$.pipe(
        filter(direction => direction === Direction.Up)
      );

      const goingDown$ = scroll$.pipe(
        filter(direction => direction === Direction.Down)
      );

      const bounce$ = scroll$.pipe(
        filter(direction => direction === Direction.Bounce)
      );

      goingUp$.subscribe(() => (this.isVisible = true));
      goingDown$.subscribe(() => (this.isVisible = false));
      bounce$.subscribe(() => (this.isVisible = true));
    }
  }
}
