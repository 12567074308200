<div class="row justify-content-center" id="site-searchbar">
  <div class="col-12 col-md-8 col-lg-6">
    <vv-film-type-ahead
      (selected)="typeaheadOnSelect($event)"
      [includeFilms]="true"
      [includePersons]="true"
    >
    </vv-film-type-ahead>
  </div>
</div>
