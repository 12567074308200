import { Component, OnInit } from '@angular/core';
import { User } from '../../model/user';
import { Subscription } from 'rxjs';
import { UserInformationService } from '../../shared/user-information/user-info.service';
import { GoogleAnalyticsDirective } from '../../shared/seo/google-analytics.directive';
import { RouterLink } from '@angular/router';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FooterAppNavigationComponent } from './footer-app-navigation/footer-app-navigation.component';
import { faBookmark, faChartPie, faCheck, faComment, faEnvelope, faStar } from "@fortawesome/pro-regular-svg-icons";
import { faBookBookmark } from "@fortawesome/pro-light-svg-icons";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { NgOptimizedImage } from "@angular/common";

@Component({
  selector: 'vv-footer',
  templateUrl: './footer.html',
  styleUrls: ['./footer.scss'],
  standalone: true,
  imports: [FooterAppNavigationComponent, FontAwesomeModule, RouterLink, GoogleAnalyticsDirective, NgOptimizedImage]
})
export class FooterComponent implements OnInit {
  currentUser: User | undefined;
  protected readonly faCheck = faCheck;
  protected readonly faBookBookmark = faBookBookmark;
  protected readonly faBookmark = faBookmark;
  protected readonly faEnvelope = faEnvelope;
  protected readonly faStar = faStar;
  protected readonly faChartPie = faChartPie;
  protected readonly faComment = faComment;
  protected readonly faFacebookF = faFacebookF;

  private userSubscription!: Subscription;

  constructor(private userService: UserInformationService) {
  }

  ngOnInit(): void {
    this.userSubscription = this.userService.user$.subscribe(
      (currentUser) => {
        this.currentUser = currentUser;
      }
    );
  }
}
