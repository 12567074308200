import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ExternalRefererService {

  constructor(private http: HttpClient, @Inject(DOCUMENT) private doc: Document) {
  }

  addRefererForFilm(filmId: number) {
    const referer = this.doc.referrer;
    if (!referer || this.blacklisted(referer)) {
      return;
    }
    this.http.post('/api/film/' + filmId + '/referer', { referer: referer }).subscribe();
  }

  blacklisted(referer: string): boolean {
    return referer.includes('localhost') || referer.includes('vodeville');
  }
}
