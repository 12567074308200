import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { ToastrService } from "ngx-toastr";
import { isPlatformBrowser } from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class AppUpdateService {
  constructor(private toastr: ToastrService, private readonly updates: SwUpdate, @Inject(PLATFORM_ID) private _platformId: Object) {
    if (isPlatformBrowser(this._platformId) && this.updates.isEnabled) {
      this.updates.versionUpdates.subscribe(event => {
        switch (event.type) {
          case 'VERSION_READY':
            this.showAppUpdateAlert();
            break;
        }
      });
    }
  }

  showAppUpdateAlert() {
    console.log("Updating to a new version of Vodeville");
    this.toastr.info("Laddar ner ny version av Vodeville. Vänta...");
    this.doAppUpdate();
  }

  doAppUpdate() {
    this.updates.activateUpdate().then(() => document.location.reload());
  }
}
