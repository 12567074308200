<div class="navbar-inverse">
  <div
    class="navbar navbar-nav pb-3 navbar-dark bg-dark">
    <a [routerLinkActiveOptions]="{exact: true}" class="nav-item nav-link"
       routerLink="" routerLinkActive="active" tabindex="0">
      <fa-icon [icon]="faFilm" class="icon"></fa-icon>
      Start
    </a>
    @if (!defaultFilter) {
      <a class="nav-item nav-link" routerLink="hitta-film" routerLinkActive="active">
        <fa-icon [icon]="faSearch" class="icon"></fa-icon>
        Hitta film
      </a>
    }
    @if (defaultFilter) {
      <a [routerLink]="['hitta-film', defaultFilter]" class="nav-item nav-link"
         routerLinkActive="active">
        <fa-icon [icon]="faSearch" class="icon"></fa-icon>
        Hitta film
      </a>
    }
    <a class="nav-item nav-link" routerLink="filmtips" routerLinkActive="active">
      <fa-icon [icon]="faLightbulb"></fa-icon>
      Filmtips
    </a>
    <a class="nav-item nav-link" routerLink="jamfor-tjanster" routerLinkActive="active">
      <fa-icon [icon]="faChartBar"></fa-icon>
      Filmtjänster
    </a>
    @if (!currentUser) {
      <a class="nav-item nav-link" rel="nofollow" routerLink="login" routerLinkActive="active">
        <fa-icon [icon]="faSignIn"></fa-icon>
        Logga in
      </a>
    }
    @if (currentUser) {
      <a class="nav-item nav-link" rel="nofollow" routerLink="/din-sida"
         routerLinkActive="active">
        <fa-icon [icon]="faUser"></fa-icon>
        Din sida
      </a>
    }
  </div>
</div>
