import {
  InMemoryScrollingFeature,
  InMemoryScrollingOptions,
  provideRouter,
  RouteReuseStrategy,
  withInMemoryScrolling
} from '@angular/router';
import { ApplicationConfig, importProvidersFrom, isDevMode, provideZoneChangeDetection } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { CustomReuseStrategy, routes } from "./app.routing";
import { provideToastr } from "ngx-toastr";
import { JwtModule } from "@auth0/angular-jwt";
import { registerLocaleData } from "@angular/common";
import localeSv from '@angular/common/locales/sv';
import { provideServiceWorker, ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { provideAnimations } from "@angular/platform-browser/animations";
import { provideClientHydration, withHttpTransferCacheOptions } from "@angular/platform-browser";
import { ErrorInterceptorService } from "./shared/error-interceptor";
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig } from "@abacritt/angularx-social-login";

registerLocaleData(localeSv, 'sv');

function tokenGetter() {
  try {
    // in browser
    return localStorage.getItem('vodeville.authToken');
  } catch (exception) {
    return null;
  }
}

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
};
const inMemoryScrollingFeature: InMemoryScrollingFeature =
  withInMemoryScrolling(scrollConfig);
export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, inMemoryScrollingFeature),
    provideToastr(),
    provideAnimations(),
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    // Experimental in angular 18, to reduce the number of change detection cycles in new zone.js apps
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideClientHydration(withHttpTransferCacheOptions({
      includePostRequests: true
    })),
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('470250581947-do09ljk5ohek1bhfb7es1tci9oa0ro6m.apps.googleusercontent.com')
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('1556677934565840')
          }
        ]
      } as SocialAuthServiceConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true
    },
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
    importProvidersFrom(// TODO provide right way for standalone app
      JwtModule.forRoot({
        config: {
          tokenGetter: tokenGetter,
          allowedDomains: ['localhost:4200', 'vodeville.se', 'localhost.vodeville.se:4200']
        }
      }), ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
};
